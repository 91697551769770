<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
    <div class="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
      <div>
        <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
          Resources
        </h2>
        <p class="mt-3 text-xl text-gray-500 sm:mt-4">
          Check out our guides to optimize your email-to-Notion workflow.
        </p>
      </div>
      <div class="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
        <div v-for="post in posts" :key="post.title">
          <div>
            <a :href="post.category.href" class="inline-block">
              <span :class="[post.category.color, 'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium']">
                {{ post.category.name }}
              </span>
            </a>
          </div>
          <router-link :to="post.href" class="block mt-4">
            <p class="text-xl font-semibold text-gray-900">
              {{ post.title }}
            </p>
            <p class="mt-3 text-base text-gray-500">
              {{ post.description }}
            </p>
          </router-link>
          <div class="mt-6 flex items-center">
            <div class="ml-3">
              <div class="flex space-x-1 text-sm text-gray-500">
                <time :datetime="post.datetime">
                  {{ post.date }}
                </time>
                <span aria-hidden="true">
                  &middot;
                </span>
                <span> {{ post.readingTime }} read </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const posts = [
  {
    title: 'Getting Started',
    href: '#',
    category: { name: 'Video', href: '#', color: 'bg-green-100 text-green-800' },
    description: 'A quick walkthrough for new users.',
    date: 'Feb 12, 2021',
    datetime: '2021-02-12',
    readingTime: '11 min',
  },
  {
    title: '3 ways to forward emails to BlockBase',
    href: '#',
    category: { name: 'Article', href: '#', color: 'bg-indigo-100 text-indigo-800' },
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'Mar 16, 2021',
    datetime: '2021-03-16',
    readingTime: '6 min',
  },
  {
    title: 'Automatically tag emails in your Notion DB',
    href: '#',
    category: { name: 'Advanced', href: '#', color: 'bg-pink-100 text-pink-800' },
    description: 'Learn how to use "plus addressing" to apply custom tags.',
    date: 'Mar 10, 2021',
    datetime: '2021-03-10',
    readingTime: '4 min',
  },
]

export default {
  setup() {
    return {
      posts,
    }
  },
}
</script>
