<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="md:flex md:items-center md:justify-center">
      <div class="mt-4 flex md:mt-0 md:ml-4">
        <a href="/help-work-in-progress#faqs" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          FAQs
        </a>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4">
        <router-link to="/contact" type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Contact Support
        </router-link>
      </div>
    </div>
    <Resources />
    <a name="faqs" />
    <FAQ />
  </div>
</template>

<script>
import FAQ from '@/components/FAQ.vue'
import Resources from '@/components/Resources.vue'

export default {
  data () {
    return {
    }
  },
  components: {
    FAQ,
    Resources
  }
}
</script>
